/* Can't style the html tag with theme-ui, so put styles in here */

html {
  height: 100%;
}

table, td {
  border-spacing: 0 !important;
  border-bottom: 1px solid rgba(36, 38, 37,0.7);
}

